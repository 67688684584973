<template>
  <button class="button button-transparent" @click="tryLog()">
    {{ button }}
  </button>
  <Teleport to="body">
    <Transition name="modal">
      <div v-if="modalLogin" class="modal">
        <div class="modal-container" ref="modal">
          <h3 class="modal-title">Вхід</h3>
          <form>
            <div class="container">
              <input
                v-model="form.login"
                type="text"
                class="contacts-form-input"
                placeholder="Login"
                :class="{ 'contacts-form-input-error': errorFields?.login?.length && isSubmitted}"
              />
              <div v-if="errorFields?.login?.length && isSubmitted" class="modal-error">
                {{ errorFields.login[0].message }}
              </div>
            </div>
            <div class="container">
              <input
                v-model="form.password"
                type="text"
                class="contacts-form-input"
                placeholder="Password"
                :class="{ 'contacts-form-input-error': errorFields?.password?.length && isSubmitted}"
              />
              <div v-if="errorFields?.password?.length && isSubmitted" class="modal-error">
                {{ errorFields.password[0].message }}
              </div>
            </div>
            <button type="submit" @click.prevent="signin()" class="button button-filled">
              Увійти
            </button>
          </form>
          <button type="submit" @click="toggleModal()" class="modal-exit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M2 2L12 12M12 12L22 22M12 12L2 22M12 12L22 2"
                stroke="#A1A4C5"
                stroke-width="3"
              />
            </svg>
          </button>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup>
import { reactive } from "vue";
import { ref, watch, defineProps } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useUserStore } from "../store/user";
import { useAsyncValidator } from "@vueuse/integrations/useAsyncValidator";
import { scrollFix } from '../directives/scrollFix'
import router from "@/router";

defineProps({
  button: {
    type: String,
  },
});

const userStore = useUserStore();

const isSubmitted = ref(false);
const modalLogin = ref(false);
const modal = ref(null);

const form = reactive({ login: "", password: "" });

const rules = {
  login: {
    type: "string",
    min: 4,
    required: true,
  },
  password: {
    type: "string",
    min: 4,
    required: true,
  },
};

const { pass, errorFields } = useAsyncValidator(form, rules);

watch(modalLogin, (newVal) => {
  if (window.screen.width > 1024) {
    scrollFix(newVal)
  }
});

onClickOutside(modal, () => (modalLogin.value = !modalLogin.value));

function tryLog() {
  if (localStorage.getItem("kiddy-land-uid") === null) {
    toggleModal()
  } else {
    router.push({name: 'Home'})
  }
}

function toggleModal() {
  modalLogin.value = !modalLogin.value;
}

// auth/user-not-found
// auth/wrong-password
// auth/too-many-requests

async function signin() {
  if (pass.value) {
    await userStore.signIn(form.login, form.password)
  } else {
    isSubmitted.value = true;
  }
}

</script>

<style lang="scss">
.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: scale(1.2);
}

.header,
.description {
  .button-transparent {
    padding-left: 50px;
    padding-right: 50px;
  }
  .button-filled {
    padding-left: 44px;
    padding-right: 44px;
  }
}

.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #575a7a80;
  text-align: center;
  &-error {
    position: absolute;
    bottom: -20px;
    left: 8%;
    font-size: 12px;
    color: $error-color;
    font-family: 'Fixel SemiBold';
  }
  &-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 65px 140px 50px;
    border-radius: 24px;
    background-color: $white-bg;
  }
  &-exit {
    position: absolute;
    top: 30px;
    right: 30px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  &-title {
    font-size: 64px;
    margin-bottom: 35px;
  }
  .container {
    position: relative;
    margin-bottom: 35px;
    &:not(:first-of-type) {
      margin-bottom: 50px;
    }
  }
  .contacts-form-input {
    border: 2px solid $grey-200;
    width: 300px;
    &-error {
      border: 2px solid $error-color;
    }
  }
  .button {
    width: 180px;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
