<template>
  <article
    class="task"
    v-if="task"
    :class="{ 'task-active': selectedTask === index }"
  >
    <div>
      <input
        type="radio"
        :id="`task-${index}`"
        :name="`task-${index}`"
        :value="index"
        v-model="selectedTask"
      />
      <label :for="`task-${index}`" class="task-label" @click="hide()">
        <div class="task-title">
          <img
            src="@/assets/platform/task-icons/vocabulary.svg"
            alt="vocabulary-icon"
          />
          <h4>Vocabulary</h4>
        </div>
        <img
          src="@/assets/platform/task-icons/arrow.svg"
          class="task-arrow-icon"
          :class="{ 'task-arrow-icon-active': selectedTask === index }"
        />
      </label>
    </div>
    <div
      class="task-container"
      :class="{ 'task-container-visible': selectedTask === index }"
    >
      <h4 class="task-item">
        <div v-if="titleSound !== null">
          <audio controls ref="audioTitle" class="audio-default">
            <source :src="titleSound" />
            Your browser does not support the audio element.
          </audio>
          <div class="audio-custom" @click.prevent="audioTitle.play()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <g clip-path="url(#clip0_722_970)">
                <path
                  d="M11.9641 6.40741C12.9515 7.72445 12.9515 10.2756 11.9641 11.5926M14.1863 3.81482C17.1404 6.63556 17.1582 11.383 14.1863 14.1852M1.59375 11.1919V6.80741C1.59375 6.38222 1.9256 6.03704 2.33449 6.03704H4.99079C5.08881 6.03669 5.18575 6.01649 5.27576 5.97766C5.36576 5.93884 5.44697 5.88218 5.51449 5.81111L7.73671 3.30148C8.20338 2.81556 9.00116 3.16 9.00116 3.84667V14.1533C9.00116 14.8452 8.19375 15.1867 7.73005 14.6911L5.51523 12.1956C5.44752 12.1225 5.36549 12.0641 5.27425 12.0241C5.183 11.9841 5.08449 11.9633 4.98486 11.963H2.33449C1.9256 11.963 1.59375 11.6178 1.59375 11.1919Z"
                  stroke="#1C1D28"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_722_970">
                  <rect
                    width="17.7778"
                    height="17.7778"
                    fill="white"
                    transform="translate(0.111328 0.111115)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        {{ task.title }}
      </h4>
      <div
        style="text-align: center"
        v-if="taskContent !== null"
        class="vocabulary-container"
      >
        <div
          v-for="(item, index) in taskContent.items"
          :key="index"
          class="vocabulary-item"
        >
          <img :src="item.image" />
          <div class="vocabulary-item-description">
            <div v-if="item.sound">
              <audio
                controls
                :ref="(el) => (audioRef[index] = el)"
                class="audio-default"
              >
                <source :src="item.sound" />
                Your browser does not support the audio element.
              </audio>
              <div class="audio-custom" @click.stop="audioRef[index].play()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16 8.50001C17.333 10.278 17.333 13.722 16 15.5M19 5.00001C22.988 8.80801 23.012 15.217 19 19M2 14.959V9.04001C2 8.46601 2.448 8.00001 3 8.00001H6.586C6.71833 7.99954 6.8492 7.97228 6.97071 7.91986C7.09222 7.86744 7.20185 7.79095 7.293 7.69501L10.293 4.30701C10.923 3.65101 12 4.11601 12 5.04301V18.957C12 19.891 10.91 20.352 10.284 19.683L7.294 16.314C7.20259 16.2153 7.09185 16.1365 6.96867 16.0825C6.84549 16.0285 6.71251 16.0004 6.578 16H3C2.448 16 2 15.534 2 14.959Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div class="vocabulary-list-item">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script setup>
import { defineProps, ref, defineEmits } from "vue";
import { getMediaContent } from "@/firebase.js";

const props = defineProps({
  task: {
    type: Object,
  },
  index: {
    type: Number,
  },
});

const emit = defineEmits(["setProgress"]);

const selectedTask = ref(null);

const audioTitle = ref();
const audioRef = ref([]);

const titleSound = ref(null);
const taskContent = ref(null);

props.task.titleSound
  ? getMediaContent(props.task.titleSound).then((url) => {
      titleSound.value = url;
    })
  : null;

setTask();

function hide() {
  if (selectedTask.value === props.index) {
    setTimeout(() => {
      selectedTask.value = null;
    }, 100);
  }
  emit("setProgress", props.index);
}

function setTask() {
  taskContent.value = {
    items: [],
  };

  Array.from(props.task.items).forEach((element, index) => {
    taskContent.value.items[index] = {};
    getMediaContent(element.image).then((url) => {
      taskContent.value.items[index].image = url;
    });
    getMediaContent(element.sound).then((url) => {
      taskContent.value.items[index].sound = url;
    });
    taskContent.value.items[index].title = element.title;
  });
}
</script>

<style lang="scss" scoped>
.task-active {
  border: 6px solid #ffa47d;
}

.vocabulary {
  &-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 6%;
  }
  &-item {
    width: 20%;
    height: 100%;
    &-description {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    img {
      object-fit: contain;
      width: 100%;
      height: 60%;
      margin-bottom: 12px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .vocabulary {
    &-container {
      gap: 20px 5%;
    }
    &-item {
      width: 30%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .vocabulary-item-description {
    display: block;
    .audio-custom {
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 550px) {
  .vocabulary {
    &-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    &-item {
      width: 200px;
      margin: 0 auto;
      &:not(:last-child) {
        border-bottom: 2px solid $purple-200;
        padding-bottom: 20px;
      }
      &-description {
        display: flex;
        justify-content: center;
        .audio-custom {
          margin-bottom: 0;
        }
      }
    }
  }
}

</style>
