export function handleLocalStorage(val) {
  if (val) {
   if (localStorage.getItem("kiddy-land-uid") === null) {
    localStorage.setItem("kiddy-land-uid", val)
   } else {
    localStorage.removeItem("kiddy-land-uid");
    localStorage.setItem("kiddy-land-uid", val)
   }
  } else {
    localStorage.removeItem("kiddy-land-uid");
  }
}