<template>
    <LandingComponent />
</template>

<script>
import LandingComponent from '@/components/LandingComponent.vue'

export default {
  name: 'HomeView',
  components: {
    LandingComponent
  }
}
</script>

