<template>
  <article
    class="task"
    v-if="task"
    :class="{ 'task-active': selectedTask === index }"
  >
    <div>
      <input
        type="radio"
        :id="`task-${index}`"
        :name="`task-${index}`"
        :value="index"
        v-model="selectedTask"
      />
      <label :for="`task-${index}`" class="task-label" @click="hide()">
        <div class="task-title">
          <img
            src="@/assets/platform/task-icons/listening.svg"
            alt="listening-icon"
          />
          <h4>Listen and choose</h4>
        </div>
        <img
          src="@/assets/platform/task-icons/arrow.svg"
          class="task-arrow-icon"
          :class="{ 'task-arrow-icon-active': selectedTask === index }"
        />
      </label>
    </div>
    <div
      class="task-container"
      :class="{ 'task-container-visible': selectedTask === index }"
    >
      <h4 class="task-item">
        <div v-if="titleSound !== null">
          <audio controls ref="audioTitle" class="audio-default">
            <source :src="titleSound" />
            Your browser does not support the audio element.
          </audio>
          <div class="audio-custom" @click.prevent="audioTitle.play()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <g clip-path="url(#clip0_722_970)">
                <path
                  d="M11.9641 6.40741C12.9515 7.72445 12.9515 10.2756 11.9641 11.5926M14.1863 3.81482C17.1404 6.63556 17.1582 11.383 14.1863 14.1852M1.59375 11.1919V6.80741C1.59375 6.38222 1.9256 6.03704 2.33449 6.03704H4.99079C5.08881 6.03669 5.18575 6.01649 5.27576 5.97766C5.36576 5.93884 5.44697 5.88218 5.51449 5.81111L7.73671 3.30148C8.20338 2.81556 9.00116 3.16 9.00116 3.84667V14.1533C9.00116 14.8452 8.19375 15.1867 7.73005 14.6911L5.51523 12.1956C5.44752 12.1225 5.36549 12.0641 5.27425 12.0241C5.183 11.9841 5.08449 11.9633 4.98486 11.963H2.33449C1.9256 11.963 1.59375 11.6178 1.59375 11.1919Z"
                  stroke="#1C1D28"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_722_970">
                  <rect
                    width="17.7778"
                    height="17.7778"
                    fill="white"
                    transform="translate(0.111328 0.111115)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        {{ task.title }}
      </h4>
      <div style="text-align: center" v-if="taskContent !== null">
        <div
          v-for="(item, index) in taskContent.items"
          :key="index"
          class="task-listening"
        >
          <div v-if="isReady[index]" class="task-listening-wrapper">
            <audio
              controls
              :ref="(el) => (audioRef[index] = el)"
              class="audio-default"
            >
              <source :src="item.sound" />
              Your browser does not support the audio element.
            </audio>
            <div class="audio-custom" @click.stop="audioRef[index].play()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M16 8.50001C17.333 10.278 17.333 13.722 16 15.5M19 5.00001C22.988 8.80801 23.012 15.217 19 19M2 14.959V9.04001C2 8.46601 2.448 8.00001 3 8.00001H6.586C6.71833 7.99954 6.8492 7.97228 6.97071 7.91986C7.09222 7.86744 7.20185 7.79095 7.293 7.69501L10.293 4.30701C10.923 3.65101 12 4.11601 12 5.04301V18.957C12 19.891 10.91 20.352 10.284 19.683L7.294 16.314C7.20259 16.2153 7.09185 16.1365 6.96867 16.0825C6.84549 16.0285 6.71251 16.0004 6.578 16H3C2.448 16 2 15.534 2 14.959Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div class="task-listening-container">
            <div v-for="(item2, index2) in item.answers" :key="index2">
              <input
                v-if="item2 && selectedItem[index]"
                type="radio"
                :id="`task-listening-item-${index}-${index2}-${props.index}`"
                :name="`task-listening-item-${index}-${props.index}`"
                :value="{ value: item2.value, id: index2 }"
                v-model="selectedItem[index]"
                :disabled="
                  selectedItem[index].value === true ||
                  selectedItem[index].value === false
                "
              />
              <label
                v-if="item2 && selectedItem[index]"
                :for="`task-listening-item-${index}-${index2}-${props.index}`"
                class="task-listening-label"
                :class="{
                  'task-listening-label-checked':
                    selectedItem[index].value === true ||
                    selectedItem[index].value === false,
                  'task-listening-label-true':
                    selectedItem[index].value === true &&
                    selectedItem[index].id === index2,
                  'task-listening-label-false':
                    selectedItem[index].value === false &&
                    selectedItem[index].id === index2,
                }"
              >
                <img :src="item2.image" class="task-listening-img" />
                <div
                  class="task-listening-label-background"
                  :class="{
                    'task-listening-label-background-true':
                      selectedItem[index].value === true &&
                      selectedItem[index].id === index2,
                    'task-listening-label-background-false':
                      selectedItem[index].value === false &&
                      selectedItem[index].id === index2,
                  }"
                ></div>
                <div
                  class="task-listening-label-icon"
                  :class="{
                    'task-listening-label-icon-true':
                      selectedItem[index].value === true &&
                      selectedItem[index].id === index2,
                    'task-listening-label-icon-false':
                      selectedItem[index].value === false &&
                      selectedItem[index].id === index2,
                  }"
                >
                  <img
                    src="@/assets/platform/states/true.svg"
                    v-if="
                      selectedItem[index].value === true &&
                      selectedItem[index].id === index2
                    "
                  />
                  <img
                    src="@/assets/platform/states/false.svg"
                    v-if="
                      selectedItem[index].value === false &&
                      selectedItem[index].id === index2
                    "
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script setup>
import { defineProps, ref, watch, defineEmits } from "vue";
import { getMediaContent } from "@/firebase.js";

const props = defineProps({
  task: {
    type: Object,
  },
  index: {
    type: Number,
  },
});

const emit = defineEmits(["setProgress"]);

const selectedTask = ref(null);
const selectedItem = ref([]);

const audioTitle = ref();

const titleSound = ref(null);

const audioRef = ref([]);
const taskContent = ref(null);

const isReady = ref([]);

watch(selectedItem.value, (newVal) => {
  let count = 0;
  newVal.forEach((element) => {
    element.value ? (count = count + 1) : null;
  });
  if (count === newVal.length) {
    emit("setProgress", props.index);
  }
});

props.task.titleSound
  ? getMediaContent(props.task.titleSound).then((url) => {
      titleSound.value = url;
    })
  : null;

setTask();

function hide() {
  if (selectedTask.value === props.index) {
    setTimeout(() => {
      selectedTask.value = null;
    }, 100);
  }
}

function setTask() {
  taskContent.value = {
    items: [],
  };

  Array.from(props.task.items).forEach((element, index) => {
    taskContent.value.items[index] = {};
    selectedItem.value.push({ value: null, id: null });

    getMediaContent(element.sound).then((url) => {
      taskContent.value.items[index].sound = url;
      isReady.value[index] = true;
    });

    element.answers.forEach((e, i) => {
      taskContent.value.items[index].answers = [];

      getMediaContent(e.image).then((url) => {
        taskContent.value.items[index].answers[i] = {
          value: e.value,
          image: url,
        };
      });
    });
  });
}
</script>

<style lang="scss" scoped>
.task-active {
  border: 6px solid #cdf1b1;
}

.task-listening {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  gap: 30px;
  &:first-child {
    padding-top: 0;
  }
  &:not(:last-child) {
    border-bottom: 2px solid $purple-200;
  }
  &-container {
    display: flex;
    gap: 25px;
    input:checked + .task-listening-label-true {
      border: 4px solid $access-color;
    }
    input:checked + .task-listening-label-false {
      border: 4px solid $error-color;
    }
  }
  &-label {
    display: block;
    position: relative;
    width: 175px;
    height: 175px;
    cursor: pointer;
    border-radius: 12px;
    padding: 15px;
    border: 4px solid $purple-200;
    user-select: none;
    &-checked {
      cursor: default;
    }
    &-background {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      &-true {
        background: linear-gradient(
          0deg,
          rgba(44, 229, 140, 0.25) 0%,
          rgba(44, 229, 140, 0.25) 100%
        );
      }
      &-false {
        background: linear-gradient(
          0deg,
          rgba(255, 117, 58, 0.25) 0%,
          rgba(255, 117, 58, 0.25) 100%
        );
      }
    }
    &-icon {
      position: absolute;
      width: 54px;
      height: 54px;
      border-radius: 100%;
      bottom: 10px;
      left: 10px;
      &-true {
        background-color: $access-color;
      }
      &-false {
        background-color: $error-color;
      }
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &-img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    // height: 175px;
    // width: 175px;
  }
}

@media screen and (max-width: 1350px) {
  .task-listening-label {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 1250px) {
  .task-listening-label {
    width: 125px;
    height: 125px;
    padding: 8px;
  }
}

@media screen and (max-width: 1150px) {
  .task-listening {
    flex-wrap: wrap;
    justify-content: center;
    &-wrapper {
      width: 100%;
      .audio-custom {
        margin: 0 auto;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .task-listening-container {
    gap: 15px;
  }
  .task-listening-label {
    width: 115px;
    height: 115px;
  }
}

@media screen and (max-width: 595px) {
  .task-listening-container {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 340px) {
  .task-listening-container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .task-listening-label {
    width: 100px;
    height: 100px;
  }
}

</style>
