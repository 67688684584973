<template>
  <article
    class="task"
    v-if="task"
    :class="{ 'task-active': selectedTask === index }"
  >
    <div>
      <input
        type="radio"
        :id="`task-${index}`"
        :name="`task-${index}`"
        :value="index"
        v-model="selectedTask"
      />
      <label :for="`task-${index}`" class="task-label" @click="hide()">
        <div class="task-title">
          <img
            src="@/assets/platform/task-icons/flashcards.svg"
            alt="flashcards-icon"
          />
          <h4>Flashcards</h4>
        </div>
        <img
          src="@/assets/platform/task-icons/arrow.svg"
          class="task-arrow-icon"
          :class="{ 'task-arrow-icon-active': selectedTask === index }"
        />
      </label>
    </div>
    <div
      class="task-container"
      :class="{ 'task-container-visible': selectedTask === index }"
    >
      <h4 class="task-item">
        <div v-if="titleSound !== null">
          <audio controls ref="audioTitle" class="audio-default">
            <source :src="titleSound" />
            Your browser does not support the audio element.
          </audio>
          <div class="audio-custom" @click.prevent="audioTitle.play()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <g clip-path="url(#clip0_722_970)">
                <path
                  d="M11.9641 6.40741C12.9515 7.72445 12.9515 10.2756 11.9641 11.5926M14.1863 3.81482C17.1404 6.63556 17.1582 11.383 14.1863 14.1852M1.59375 11.1919V6.80741C1.59375 6.38222 1.9256 6.03704 2.33449 6.03704H4.99079C5.08881 6.03669 5.18575 6.01649 5.27576 5.97766C5.36576 5.93884 5.44697 5.88218 5.51449 5.81111L7.73671 3.30148C8.20338 2.81556 9.00116 3.16 9.00116 3.84667V14.1533C9.00116 14.8452 8.19375 15.1867 7.73005 14.6911L5.51523 12.1956C5.44752 12.1225 5.36549 12.0641 5.27425 12.0241C5.183 11.9841 5.08449 11.9633 4.98486 11.963H2.33449C1.9256 11.963 1.59375 11.6178 1.59375 11.1919Z"
                  stroke="#1C1D28"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_722_970">
                  <rect
                    width="17.7778"
                    height="17.7778"
                    fill="white"
                    transform="translate(0.111328 0.111115)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        {{ task.title }}
      </h4>
      <div class="flashcards">
        <div class="flashcards-container">
          <div
            class="flashcards-button"
            @click="
              mySwiper.slidePrev();
              isRotate = false;
              progressTracker = progressTracker + 1;
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M20 11L7.83 11L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13L20 13L20 11Z"
                fill="#1C1D28"
              />
            </svg>
          </div>
          <div class="flashcards-item" v-if="taskContent !== null">
            <swiper
              :slides-per-view="1"
              :loop="true"
              :speed="700"
              @realIndexChange="changeIndex"
              @swiper="onSwiper"
            >
              <swiper-slide
                v-for="(slide, index) in taskContent.items"
                :key="index"
              >
                <div
                  class="flashcards-item-slide"
                  @click="isRotate = !isRotate"
                >
                  <div
                    class="flip-card-inner"
                    :class="{ 'flip-card-inner-rotate': isRotate }"
                  >
                    <div class="flip-card-front">
                      <img
                        :src="slide.image"
                        alt="slide"
                        class="flashcards-item-slide-img"
                      />
                    </div>
                    <div class="flip-card-back">
                      <div class="blur-fix">
                        <div v-if="slide.sound">
                          <audio
                            v-if="isReady"
                            controls
                            :ref="(el) => (audioRef[index] = el)"
                            class="audio-default"
                          >
                            <source :src="slide.sound" />
                            Your browser does not support the audio element.
                          </audio>
                          <div
                            class="audio-custom"
                            @click.stop="audioRef[index].play()"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M16 8.50001C17.333 10.278 17.333 13.722 16 15.5M19 5.00001C22.988 8.80801 23.012 15.217 19 19M2 14.959V9.04001C2 8.46601 2.448 8.00001 3 8.00001H6.586C6.71833 7.99954 6.8492 7.97228 6.97071 7.91986C7.09222 7.86744 7.20185 7.79095 7.293 7.69501L10.293 4.30701C10.923 3.65101 12 4.11601 12 5.04301V18.957C12 19.891 10.91 20.352 10.284 19.683L7.294 16.314C7.20259 16.2153 7.09185 16.1365 6.96867 16.0825C6.84549 16.0285 6.71251 16.0004 6.578 16H3C2.448 16 2 15.534 2 14.959Z"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="square"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <div class="vocabulary-list-item">
                          {{ slide.title }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div
            class="flashcards-button"
            @click="
              mySwiper.slideNext();
              isRotate = false;
              progressTracker = progressTracker + 1;
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4 13L16.17 13L10.58 18.59L12 20L20 12L12 4L10.59 5.41L16.17 11L4 11L4 13Z"
                fill="#1C1D28"
              />
            </svg>
          </div>
        </div>
        <div class="flashcards-controls">
          <div class="flashcards-controls-index">
            <p class="flashcards-controls-index-current">
              {{ activeIndex + 1 }}
            </p>
            <p class="flashcards-controls-index-max">
              {{ taskContent.items.length }}
            </p>
          </div>
          <div class="flashcards-controls-buttons">
            <p @click="setTask()">Reset</p>
            <p @click="shuffle()">Shuffle</p>
          </div>
        </div>
        <div class="flashcards-controls-mobile">
          <div class="flashcards-controls-mobile-container">
            <div
              class="flashcards-button"
              @click="
                mySwiper.slidePrev();
                isRotate = false;
                progressTracker = progressTracker + 1;
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M20 11L7.83 11L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13L20 13L20 11Z"
                  fill="#1C1D28"
                />
              </svg>
            </div>
            <div class="flashcards-controls-index">
              <p class="flashcards-controls-index-current">
                {{ activeIndex + 1 }}
              </p>
              <p class="flashcards-controls-index-max">
                {{ taskContent.items.length }}
              </p>
            </div>
            <div
              class="flashcards-button"
              @click="
                mySwiper.slideNext();
                isRotate = false;
                progressTracker = progressTracker + 1;
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4 13L16.17 13L10.58 18.59L12 20L20 12L12 4L10.59 5.41L16.17 11L4 11L4 13Z"
                  fill="#1C1D28"
                />
              </svg>
            </div>
          </div>
          <div class="flashcards-controls-buttons">
            <p @click="setTask()">Reset</p>
            <p @click="shuffle()">Shuffle</p>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script setup>
import { defineProps, ref, watch, defineEmits } from "vue";
import { getMediaContent } from "@/firebase.js";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

const props = defineProps({
  task: {
    type: Object,
  },
  index: {
    type: Number,
  },
});

const emit = defineEmits(["setProgress"]);

const audioRef = ref([]);

const selectedTask = ref(null);

const audioTitle = ref();

const titleSound = ref(null);

const activeIndex = ref(0);

const mySwiper = ref(null);

const taskContent = ref(null);

const isRotate = ref(false);
const isReady = ref(false);

const progressTracker = ref(0);

watch(progressTracker, (newVal) => {
  if (newVal === taskContent.value.items.length - 1) {
    emit("setProgress", props.index);
  }
});

props.task.titleSound
  ? getMediaContent(props.task.titleSound).then((url) => {
      titleSound.value = url;
    })
  : null;

setTask();

function hide() {
  if (selectedTask.value === props.index) {
    setTimeout(() => {
      selectedTask.value = null;
    }, 100);
  }
}

function changeIndex(e) {
  activeIndex.value = e.realIndex;
}

function onSwiper(swiper) {
  mySwiper.value = swiper;
}

function setTask() {
  taskContent.value = {
    items: [],
  };

  Array.from(props.task.items).forEach((element, index) => {
    taskContent.value.items[index] = {};
    getMediaContent(element.image).then((url) => {
      taskContent.value.items[index].image = url;
    });
    getMediaContent(element.sound).then((url) => {
      taskContent.value.items[index].sound = url;
    });
    taskContent.value.items[index].title = element.title;
  });

  if (mySwiper.value !== null) {
    isRotate.value = false;
    mySwiper.value.slideToLoop(0);
  }

  isReady.value = true;
}

function shuffle() {
  isReady.value = false;
  isRotate.value = false;

  taskContent.value.items = Array(taskContent.value.items.length)
    .fill(null)
    .map((_, i) => [Math.random(), i])
    .sort(([a], [b]) => a - b)
    .map(([, i]) => taskContent.value.items[i]);

  setTimeout(() => {
    isReady.value = true;
  }, 0);

  mySwiper.value.slideToLoop(0);
}
</script>

<style lang="scss" scoped>
.task-active {
  border: 6px solid #aafffa;
}

.flashcards {
  max-width: 100%;
  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-item {
    width: 80%;
    &-slide {
      width: 400px;
      height: 300px;
      border-radius: 40px;
      margin: 0 auto;
      margin-top: 25px;
      perspective: 1000px;
      cursor: pointer;
      &-img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-button {
    width: 54px;
    height: 54px;
    border-radius: 100%;
    border: 2px solid $purple-200;
    position: relative;
    cursor: pointer;
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &-controls {
    width: 400px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    user-select: none;
    &-mobile {
      display: none;
    }
    &-index {
      display: flex;
      gap: 15px;
      &-current {
        font-size: 16px;
        font-family: "Fixel SemiBold";
      }
      &-max {
        font-size: 12px;
        color: $grey-200;
      }
    }
    &-buttons {
      display: flex;
      gap: 40px;
      p {
        cursor: pointer;
      }
    }
  }
}

.flip-card-inner {
  border-radius: 40px;
  border: 3px solid $purple-800;
  padding: 50px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  /* Do an horizontal flip when you move the mouse over the flip box container */
  &-rotate {
    transform: rotateY(180deg);
  }
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  width: 260px;
  height: 180px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Style the back side */
.flip-card-back {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotateY(180deg);
}

.audio-custom {
  margin: 0 auto;
}

.vocabulary-list-item {
  margin-top: 15px;
  display: inline-block;
}

.blur-fix {
  width: 260px;
  height: 104px;
}

.task-container h4 {
  margin-bottom: 10px;
}

@media screen and (max-width: 595px) {
  .flashcards {
    &-item {
      width: 100%;
      &-slide {
        width: 300px;
        height: 260px;
      }
    }
    &-button {
      display: none;
    }
    &-controls {
      display: none;
      &-buttons {
        justify-content: center;
        font-size: 14px;
      }
      &-mobile {
        display: block;
        .flashcards-button {
          display: block;
        }
        &-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 395px) {
  .flashcards {
    &-item {
      &-slide {
        width: 225px;
        height: 190px;
      }
    }
  }
  .flip-card-front {
    width: 190px;
    height: 170px;
  }
}
</style>

<style lang="scss">
.swiper {
  height: 350px;
}

.swiper-slide {
  text-align: center;
}

@media screen and (max-width: 595px) {
  .swiper {
    height: 300px;
  }
}

@media screen and (max-width: 395px) {
  .swiper {
    height: 240px;
  }
}
</style>
