<template>
  <main class="main" :class="{ 'main-hide': isNav === true }">
    <header class="platform-header">
      <button class="back" @click="handleNav()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20 11L7.83 11L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13L20 13L20 11Z"
            fill="black"
          />
        </svg>
      </button>
      <button class="logout" @click="logout()">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" fill="white" />
          <g clip-path="url(#clip0_1_4)">
            <path
              d="M18.7246 12.1621C19.0908 11.7959 19.0908 11.2012 18.7246 10.835L14.9746 7.08496C14.6084 6.71875 14.0137 6.71875 13.6475 7.08496C13.2812 7.45117 13.2812 8.0459 13.6475 8.41211L15.7979 10.5625H9.625C9.10645 10.5625 8.6875 10.9814 8.6875 11.5C8.6875 12.0186 9.10645 12.4375 9.625 12.4375H15.7979L13.6475 14.5879C13.2812 14.9541 13.2812 15.5488 13.6475 15.915C14.0137 16.2813 14.6084 16.2813 14.9746 15.915L18.7246 12.165V12.1621ZM8.6875 6.8125C9.20605 6.8125 9.625 6.39355 9.625 5.875C9.625 5.35645 9.20605 4.9375 8.6875 4.9375H6.8125C5.25977 4.9375 4 6.19727 4 7.75V15.25C4 16.8027 5.25977 18.0625 6.8125 18.0625H8.6875C9.20605 18.0625 9.625 17.6436 9.625 17.125C9.625 16.6064 9.20605 16.1875 8.6875 16.1875H6.8125C6.29395 16.1875 5.875 15.7686 5.875 15.25V7.75C5.875 7.23145 6.29395 6.8125 6.8125 6.8125H8.6875Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_4">
              <rect
                width="15"
                height="15"
                fill="white"
                transform="translate(4 4)"
              />
            </clipPath>
          </defs>
        </svg>
        <p>Вийти</p>
      </button>
    </header>
    <div class="container" v-if="!isLesson && currentItem.unit !== null">
      <LessonProgress :item="currentItem" :progress="currentItemProgress" />
      <VocabularyPreview :unit="currentItem.unit" />
      <StartLessonComponent
        @start-lesson="startLesson"
        :attempts="currentItemProgress.attempts"
      />
    </div>
    <div class="container" v-if="isLesson">
      <div v-for="(task, index) in currentItem.lesson.content" :key="index">
        <TaskVideoComponent
          :task="task"
          :index="index"
          @set-progress="setProgress"
          v-if="task.type === 'video'"
        />
        <TaskMatchComponent
          :task="task"
          :index="index"
          @set-progress="setProgress"
          v-if="task.type === 'match'"
        />
        <TaskTrueFalseComponent
          :task="task"
          :index="index"
          @set-progress="setProgress"
          v-if="task.type === 'truefalse'"
        />
        <TaskListeningComponent
          :task="task"
          :index="index"
          @set-progress="setProgress"
          v-if="task.type === 'listening'"
        />
        <TaskFlashcardsComponent
          :task="task"
          :index="index"
          @set-progress="setProgress"
          v-if="task.type === 'cards'"
        />
        <TaskVocabularyComponent
          :task="task"
          :index="index"
          @set-progress="setProgress"
          v-if="task.type === 'vocabulary'"
        />
      </div>
    </div>
  </main>
</template>

<script setup>
import { useUserStore } from "../../store/user";
import { useRoute } from "vue-router";
import { ref, watch, defineProps, defineEmits } from "vue";
import LessonProgress from "./lesson/lesson-preview/LessonProgress.vue";
import VocabularyPreview from "./lesson/lesson-preview/VocabularyPreview.vue";
import StartLessonComponent from "./lesson/lesson-preview/StartLessonComponent.vue";
import TaskVideoComponent from "./lesson/tasks/TaskVideoComponent.vue";
import TaskMatchComponent from "./lesson/tasks/TaskMatchComponent.vue";
import TaskTrueFalseComponent from "./lesson/tasks/TaskTrueFalseComponent.vue";
import TaskListeningComponent from "./lesson/tasks/TaskListeningComponent.vue";
import TaskFlashcardsComponent from "./lesson/tasks/TaskFlashcardsComponent.vue";
import TaskVocabularyComponent from "./lesson/tasks/TaskVocabularyComponent.vue";

const props = defineProps({
  units: {
    type: Array,
  },
  isNav: {
    type: Boolean,
  },
});

const emit = defineEmits(["isNav"]);

const route = useRoute();
const userStore = useUserStore();

const isReady = ref(false);
const isLesson = ref(false);

const currentItem = ref({ unit: null, lesson: null });
const currentItemProgress = ref({ attempts: null });

watch(props.units, (newVal) => {
  newVal ? (isReady.value = true) : false;
  setCurrentItem();
});

watch(
  () => route.query,
  (newVal) => {
    isLesson.value = false;
    if (newVal.unitId && newVal.lessonId && isReady.value) {
      setCurrentItem();
    }
  }
);

async function startLesson() {
  isLesson.value = true;
  userStore.updateUserAttempts(
    `unit${+route.query.unitId + 1}`,
    `lesson${+route.query.lessonId + 1}`,
    currentItemProgress.value.attempts + 1
  );
}

function setCurrentItem() {
  if (!isNaN(route.query.unitId) && !isNaN(route.query.lessonId)) {
    currentItem.value.unit = props.units[route.query.unitId];
    currentItem.value.lesson =
      props.units[route.query.unitId].lessons[route.query.lessonId];
    userStore.getProgressData().then((docSnapshot) => {
      currentItemProgress.value =
        docSnapshot.data().progress[`unit${+route.query.unitId + 1}`][
          `lesson${+route.query.lessonId + 1}`
        ];
    });
  }
}

async function logout() {
  await userStore.logOut();
}

function setProgress(value) {
  userStore.updateUserProgress(
    `unit${+route.query.unitId + 1}`,
    `lesson${+route.query.lessonId + 1}`,
    `task${value + 1}`
  );
}

function handleNav() {
  emit("isNav", true);
}

</script>

<style lang="scss" scoped>
.platform-header {
  position: fixed;
  z-index: 100;
  height: 80px;
  width: calc(100% - $nav-width);
  padding-top: 20px;
  padding-right: 110px;
  text-align: end;
  background-color: $white-bg;
  .logout {
    border-radius: 70px;
    padding: 10px 25px;
    background-color: #fff;
    cursor: pointer;
    border: none;
    outline: none;
    display: flex;
    align-items: flex-end;
    margin-left: auto;
    gap: 8px;
    p {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.main {
  padding-left: $nav-width;
  background-color: $white-bg;
  width: 100%;
  min-height: 100vh;
  .container {
    padding: 110px;
  }
}

.back {
  display: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@media screen and (max-width: 1500px) {
  .platform-header {
    padding-right: 50px;
  }
  .main .container {
    padding: 110px 50px;
  }
}

@media screen and (max-width: 1024px) {
  .platform-header {
    width: calc(100% - $nav-width-tablet);
    padding-right: 20px;
  }
  .main {
    padding-left: $nav-width-tablet;
    .container {
      padding: 110px 20px;
    }
  }
}

@media screen and (max-width: 818px) {
  .platform-header {
    width: calc(100% - $nav-width-tablet-mini);
  }
  .main {
    padding-left: $nav-width-tablet-mini;
  }
}

@media screen and (max-width: 750px) {
  .back {
    display: block;
  }
  .platform-header {
    width: 100%;
    height: 60px;
    padding-top: 0;
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main {
    padding-left: 0;
    .container {
      padding: 80px 20px 40px;
    }
    &-hide {
      opacity: 0;
      position: absolute;
    }
  }
}
</style>

<style lang="scss">
.task {
  background-color: #fff;
  border-radius: 24px;
  margin-bottom: 16px;
  &-container {
    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.4s ease-out;
    overflow: hidden;
    height: 0;
    &-visible {
      transform: scaleY(1);
      height: auto;
      padding: 0 56px 56px;
    }
    h4 {
      margin-bottom: 35px;
    }
  }
  &-title {
    display: flex;
    align-items: center;
    gap: 40px;
  }
  input {
    position: absolute;
    opacity: 0;
  }
  &-label {
    padding: 26px 56px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
  }
  &-arrow-icon {
    transition: transform 0.3s ease-out;
    &-active {
      transform: scale(1, -1);
    }
  }
  &-item {
    display: flex;
    gap: 15px;
    align-items: center;
    .audio-custom {
      width: 40px;
      height: 40px;
      background-color: transparent;
      border: 2px solid $purple-200;
    }
  }
}
.task-active {
  .task-label {
    padding: 20px 50px 30px;
  }
  .task-container-visible {
    padding: 0 50px 50px;
  }
}

.audio {
  &-default {
    position: absolute;
    opacity: 0;
    z-index: -1;
    left: -10000px;
  }
  &-custom {
    width: 54px;
    height: 54px;
    background-color: $purple-800;
    border-radius: 100%;
    cursor: pointer;
    position: relative;
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media screen and (max-width: 1024px) {
  .task-label {
    padding: 26px 26px 30px;
  }

  .task-active .task-label {
    padding: 20px;
  }

  .task-active .task-container-visible {
    padding: 0 20px 20px;
  }
}

@media screen and (max-width: 550px) {
  .task {
    &-title {
      gap: 25px;
    }
  }
}
</style>
