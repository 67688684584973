<template>
  <h1>PAGE NOT FOUND</h1>
</template>

<script>
export default {
  name: "PageNotFound",
  components: {},
};
</script>
