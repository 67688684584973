import { defineStore } from "pinia";
import { login, logout, auth, initializeUser, setUserData, setNewUser, setAttempt, setTaskProgress, getUserProgress } from "@/firebase.js";
import router from "@/router";

export const useUserStore = defineStore("user", {
  state: () => ({
    auth: auth,
    uid: null,
    user: null,
    userDoc: null,
    userProgress: null,
    units: null,
    demoEmailTemplate: "demo",
    emailAndPasswordTemplate: "@kiddyland.com",
    error: {
      errorCode: null,
      errorMessage: null,
    },
  }),

  actions: {

    async signIn(email, password) {
      login(
        email + this.emailAndPasswordTemplate,
        password + this.emailAndPasswordTemplate
      )
        .then((userCredential) => {
          this.setUser(userCredential);
          router.push({name: 'Home'})
        })
        .catch((error) => {
          this.setUser(null, error);
        });
    },

    async logOut() {
      logout()
        .then(() => {
          router.push({name: 'Landing'})
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async registerUser() {
      (localStorage.getItem("kiddy-land-uid") !== null) ? (this.uid = localStorage.getItem("kiddy-land-uid")) : (this.uid = this.auth.currentUser.uid);
      const userRef = initializeUser(this.uid);
      userRef.then((res) => {
        this.userDoc = res;
        this.userDoc.get().then((docSnapshot) => {
          if (docSnapshot.exists) {
            this.userDoc.onSnapshot((doc) => {
              if (isNaN(new Date(doc.data().expiredAt).getTime())) {
                if (doc.data().expiredAt.toMillis() > Date.now()) {
                  this.updateUserMetadata(doc)
                  this.setProgress(this.uid)
                } else this.logOut()
              } else {
                if (new Date(doc.data().expiredAt).getTime() > Date.now()) {
                  this.updateUserMetadata(doc)
                  this.setProgress(this.uid)
                } else this.logOut()
              }
            });
          } else {
            // timeout here to fix setting new user progress, when this.units still null, but setNewUserProgress() has been called.
            setTimeout(() => {
              this.setNewUserData(this.uid)
            }, 0)
          }
        })
      })
    },

    setUser(user, error = null) {
      user ? (this.user = user.user) : (this.user = null);
      error
        ? ((this.error.errorCode = error.code),
          (this.error.errorMessage = error.message))
        : ((this.error.errorCode = null), (this.error.errorMessage = null));
    },

    setProgress(uid) {
      getUserProgress(uid).then((docSnapshot) => {
        this.userProgress = docSnapshot.data().progress;
      })
    },

    setUnits(value) {
      this.units = value;
    },

    setNewUserData(uid) {
      const userData = {
        name: this.auth.currentUser.email,
        metadata: this.auth.currentUser.metadata,
        // Default subscription time - 1 month
        expiredAt: new Date(+this.auth.currentUser.metadata.createdAt + 2678400000),
        progress: this.setNewUserProgress(),
      }
      setNewUser(uid, userData).then(() => {
        this.registerUser()
      })
    },

    setNewUserProgress() {
      const progress = {};
      for (let index1 = 0; index1 < Array.from(this.units).length; index1++) {
        progress[`unit${index1+1}`] = {};
        for (let index2 = 0; index2 < Array.from(this.units[index1].lessons).length; index2++) {
          progress[`unit${index1+1}`][`lesson${index2+1}`] = {};
          progress[`unit${index1+1}`][`lesson${index2+1}`].attempts = 0;
          if (this.units[index1].lessons[index2].content) {
            for (let index3 = 0; index3 < Array.from(this.units[index1].lessons[index2].content).length; index3++) {
              progress[`unit${index1+1}`][`lesson${index2+1}`][`task${index3+1}`] = false;
            }
          }
        }
      }
      return progress;
    },

    updateUserMetadata(doc) {
      setUserData(doc, {
        metadata: this.auth.currentUser.metadata
      })
    },

    updateUserProgress(unitId, lessonId, taskId) {
      setTaskProgress(this.userDoc, unitId, lessonId, taskId)
    },

    updateUserAttempts(unitId, lessonId, attempts) {
      setAttempt(this.userDoc, unitId, lessonId, attempts)
    },

    getProgressData() {
      return getUserProgress(this.uid);
    }

  },

  getters: {
    getUser() {
      return this.user;
    },
    getAuth() {
      return this.auth;
    },
  },
});
