import { ref } from 'vue'

export function scrollFix(val) {
  var x = ref(null)
  var y = ref(null)
  if (val) {
    x = window.scrollX;
    y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  } else {
    window.onscroll = function () {};
  }
}