import { createRouter, createWebHistory } from 'vue-router'
import LandingView from '../views/HomeView.vue'
import PlatformView from '../views/PlatformView.vue'
import PageNotFoundView from '../views/PageNotFoundView.vue'

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: LandingView
  },
  {
    path: '/home',
    name: 'Home',
    component: PlatformView,
    children: [
      { path: '?unit=:unitId', name: 'Unit', component: PlatformView },
      { path: '?unit=:unitId&lesson=:lessonId', name: 'Lesson', component: PlatformView }
    ],
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("kiddy-land-uid") === null) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFoundView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
