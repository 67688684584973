import firebase from "firebase/compat/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {
  collection,
  getDocs,
  getDoc,
  query,
  updateDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { handleLocalStorage } from "./directives/localStorageHandle";

const firebaseConfig = {
  apiKey: "AIzaSyCC2ABz042AF2PpdvSl9nWd2rI2Dpu6-Ow",
  authDomain: "kiddyland-7527c.firebaseapp.com",
  projectId: "kiddyland-7527c",
  storageBucket: "kiddyland-7527c.appspot.com",
  messagingSenderId: "378984071882",
  appId: "1:378984071882:web:c69c4f9a34a048adbf6a33",
  measurementId: "G-HKCJKX2GXG",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

const storage = getStorage();

export const auth = getAuth();

auth.onAuthStateChanged(function (user) {
  if (user) {
    handleLocalStorage(user.uid);
  } else {
    handleLocalStorage(null);
  }
});

export const logout = async () => {
  return await signOut(auth);
};

export const login = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password);
};

export const getUnits = async (isDemo) => {
  if (isDemo) {
    const docRef = doc(db, "Units", "Unit-1");
    return await getDoc(docRef);
  } else {
    const q = query(collection(db, "Units"));
    return await getDocs(q);
  }
};

export const getMediaContent = async (path) => {
  return await getDownloadURL(ref(storage, path));
};

export const getUserProgress = async (uid) => {
  const docRef = doc(db, "Users", uid);
  return await getDoc(docRef);
};

export const initializeUser = async (uid) => {
  return db.collection("Users").doc(uid);
};

export const setUserData = async (doc, data) => {
  return await updateDoc(doc.ref, JSON.parse(JSON.stringify(data)));
};

export const setNewUser = async (uid, data) => {
  return await setDoc(
    doc(db, "Users", `${uid}`),
    JSON.parse(JSON.stringify(data))
  );
};

export const setTaskProgress = async (doc, unit, lesson, task) => {
  await updateDoc(doc, {
    [`progress.${unit}.${lesson}.${task}`]: true,
  });
};

export const setAttempt = async (doc, unit, lesson, attempts) => {
  await updateDoc(doc, {
    [`progress.${unit}.${lesson}.attempts`]: attempts,
  });
};
