<template>
  <nav :class="{ hide: isNav === false }">
    <div class="logo-container">
      <img src="@/assets/platform/logo.svg" alt="" />
    </div>
    <perfect-scrollbar>
      <div class="navigation-container">
        <div v-for="(item, index) in units" :key="index">
          <NavigationToggleComponent
            :unit="item"
            :index="index"
            :activeUnit="activeUnit"
            :activeLesson="activeLesson"
            @active-unit="activeUnitFunc"
            @active-lesson="activeLessonFunc"
            @is-nav="handleNav"
          />
        </div>
      </div>
    </perfect-scrollbar>
  </nav>
</template>

<script setup>
import NavigationToggleComponent from "@/components/platform/NavigationToggleComponent.vue";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import { ref, onMounted, defineProps, defineEmits } from "vue";
import router from "@/router";
import { useRoute } from "vue-router";

defineProps({
  units: {
    type: Array,
  },
  isNav: {
    type: Boolean,
  },
});

const activeUnit = ref(null);
const activeLesson = ref(null);
const route = useRoute();

const emit = defineEmits(["isNav"]);

onMounted(() => {
  if (route.query) {
    activeUnit.value = +route.query.unitId;
    activeLesson.value = +route.query.lessonId;
  }
});

function activeUnitFunc(value) {
  activeUnit.value = value;
}

function activeLessonFunc(value) {
  if (value !== null) {
    router.push({
      name: "Home",
      query: { unitId: activeUnit.value, lessonId: value },
    });
    handleNav(false)
  }
}

function handleNav(value) {
  emit("isNav", value);
}

</script>

<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css" />
<style lang="scss">
.ps {
  height: 100%;
  padding: 16px;
  padding-bottom: 80px;
}
</style>
<style lang="scss" scoped>
nav {
  background-color: $purple-200;
  min-height: 100vh;
  height: 100%;
  width: $nav-width;
  position: fixed;
}
.logo-container {
  padding: 10px;
  padding-left: 32px;
  border-bottom: 1px solid $white-bg;
  img {
    scale: 1.2;
  }
}

@media screen and (max-width: 1370px) {
  .ps {
    padding-bottom: 160px;
  }
}

@media screen and (max-width: 1024px) {
  nav {
    width: $nav-width-tablet;
  }
}

@media screen and (max-width: 818px) {
  nav {
    width: $nav-width-tablet-mini;
  }
  .ps {
    padding: 8px 10px 160px 8px;
  }
}

@media screen and (max-width: 750px) {
  nav {
    width: $nav-width-phone;
    z-index: 999;
    &.hide {
      z-index: -1;
      display: none;
    }
  }
  .ps {
    padding: 16px 16px 160px 16px;
  }
}
</style>
