<template>
  <article
    class="task"
    v-if="taskContent !== null"
    :class="{ 'task-active': selectedTask === index }"
  >
    <div>
      <input
        type="radio"
        :id="`task-${index}`"
        :name="`task-${index}`"
        :value="index"
        v-model="selectedTask"
      />
      <label :for="`task-${index}`" class="task-label" @click="hide()">
        <div class="task-title">
          <img src="@/assets/platform/task-icons/match.svg" alt="match-icon" />
          <h4>Matching task</h4>
        </div>
        <img
          src="@/assets/platform/task-icons/arrow.svg"
          class="task-arrow-icon"
          :class="{ 'task-arrow-icon-active': selectedTask === index }"
        />
      </label>
    </div>
    <div
      class="task-container"
      :class="{ 'task-container-visible': selectedTask === index }"
    >
      <h4 class="task-item">
        <div v-if="taskContent.titleSound">
          <audio controls ref="audioTitle" class="audio-default">
            <source :src="taskContent.titleSound" />
            Your browser does not support the audio element.
          </audio>
          <div class="audio-custom" @click.prevent="audioTitle.play()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <g clip-path="url(#clip0_722_970)">
                <path
                  d="M11.9641 6.40741C12.9515 7.72445 12.9515 10.2756 11.9641 11.5926M14.1863 3.81482C17.1404 6.63556 17.1582 11.383 14.1863 14.1852M1.59375 11.1919V6.80741C1.59375 6.38222 1.9256 6.03704 2.33449 6.03704H4.99079C5.08881 6.03669 5.18575 6.01649 5.27576 5.97766C5.36576 5.93884 5.44697 5.88218 5.51449 5.81111L7.73671 3.30148C8.20338 2.81556 9.00116 3.16 9.00116 3.84667V14.1533C9.00116 14.8452 8.19375 15.1867 7.73005 14.6911L5.51523 12.1956C5.44752 12.1225 5.36549 12.0641 5.27425 12.0241C5.183 11.9841 5.08449 11.9633 4.98486 11.963H2.33449C1.9256 11.963 1.59375 11.6178 1.59375 11.1919Z"
                  stroke="#1C1D28"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_722_970">
                  <rect
                    width="17.7778"
                    height="17.7778"
                    fill="white"
                    transform="translate(0.111328 0.111115)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        {{ task.title }}
      </h4>
      <div class="match-image" v-if="taskContent.image">
        <img :src="taskContent.image" alt="task description" />
      </div>
      <div class="match">
        <div :style="{ height: containerHeight + 'px' }">
          <div
            v-for="(item, i) in taskContent.left"
            :key="i"
            class="match-item"
            :class="[
              { 'match-item-active': selectedLeft.id === i + 1 },
              {
                'match-item-disabled':
                  disabledItems.length &&
                  disabledItems.find((element) => element.left.id === item.id),
              },
            ]"
            :id="`match-left${item.id}`"
            :ref="(el) => (leftItems[i] = el)"
          >
            <input
              type="radio"
              :id="`match-item-left-${i}-${index}`"
              :name="`match-item-left-${index}`"
              :value="{ id: item.id }"
              v-model="selectedLeft"
              :disabled="
                disabledItems.length &&
                disabledItems.find((element) => element.left.id === item.id)
              "
            />
            <label :for="`match-item-left-${i}-${index}`">
              <div
                v-if="
                  disabledItems.length &&
                  disabledItems.find((element) => element.left.id === item.id)
                "
              >
                <div
                  style="scale: 2"
                  v-if="
                    disabledItems.length &&
                    disabledItems.find(
                      (element) =>
                        element.left.id === item.id &&
                        element.left.id === element.right.answer_id
                    )
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24px"
                    height="24px"
                  >
                    <path
                      d="M10.4969 16L7 12.2079L7.87423 11.2599L10.4969 14.104L16.1258 8L17 8.94803L10.4969 16Z"
                      fill="green"
                    />
                  </svg>
                </div>
                <div
                  v-else
                  @click.prevent="separate(item.id)"
                  style="cursor: pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M2 2L12 12M12 12L22 22M12 12L2 22M12 12L22 2"
                      stroke="#A1A4C5"
                      stroke-width="3"
                    />
                  </svg>
                </div>
              </div>
              <div
                v-else
                class="circle"
                :class="[{ 'circle-active': selectedLeft.id === i + 1 }]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  width="20px"
                  height="20px"
                >
                  <path
                    d="M10.4969 16L7 12.2079L7.87423 11.2599L10.4969 14.104L16.1258 8L17 8.94803L10.4969 16Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div class="match-item-container">
                <div v-for="(url, j) in item.path" :key="j">
                  <img
                    :src="url"
                    alt=""
                    :class="`image-size-control image-size-control-${item.path.length}`"
                    v-if="isImage(url)"
                  />
                  <div v-if="isSound(url)">
                    <audio
                      controls
                      :ref="(el) => (audioLeft[i] = el)"
                      class="audio-default"
                    >
                      <source :src="url" />
                      Your browser does not support the audio element.
                    </audio>
                    <div class="audio-custom" @click.prevent="playL(i)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M16 8.50001C17.333 10.278 17.333 13.722 16 15.5M19 5.00001C22.988 8.80801 23.012 15.217 19 19M2 14.959V9.04001C2 8.46601 2.448 8.00001 3 8.00001H6.586C6.71833 7.99954 6.8492 7.97228 6.97071 7.91986C7.09222 7.86744 7.20185 7.79095 7.293 7.69501L10.293 4.30701C10.923 3.65101 12 4.11601 12 5.04301V18.957C12 19.891 10.91 20.352 10.284 19.683L7.294 16.314C7.20259 16.2153 7.09185 16.1365 6.96867 16.0825C6.84549 16.0285 6.71251 16.0004 6.578 16H3C2.448 16 2 15.534 2 14.959Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div>
        <div :style="{ height: containerHeight + 'px' }">
          <div
            v-for="(item, i) in taskContent.right"
            :key="i"
            class="match-item"
            :class="[
              { 'match-item-active': selectedRight.id === i + 1 },
              {
                'match-item-disabled':
                  disabledItems.length &&
                  disabledItems.find((element) => element.right.id === item.id),
              },
            ]"
            :id="`match-right${item.id}`"
            :ref="(el) => (rightItems[i] = el)"
          >
            <input
              type="radio"
              :id="`match-item-right-${i}-${index}`"
              :name="`match-item-right-${index}`"
              :value="{ id: item.id, answer_id: item.answer_id }"
              v-model="selectedRight"
              :disabled="
                disabledItems.length &&
                disabledItems.find((element) => element.right.id === item.id)
              "
            />
            <label :for="`match-item-right-${i}-${index}`">
              <div class="match-item-container">
                <div v-for="(url, j) in item.path" :key="j">
                  <img
                    :src="url"
                    alt=""
                    :class="`image-size-control image-size-control-${item.path.length}`"
                    v-if="isImage(url)"
                  />
                  <div v-if="isSound(url)">
                    <audio
                      controls
                      :ref="(el) => (audioRight[i] = el)"
                      class="audio-default"
                    >
                      <source :src="url" />
                      Your browser does not support the audio element.
                    </audio>
                    <div class="audio-custom" @click.prevent="playR(i)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M16 8.50001C17.333 10.278 17.333 13.722 16 15.5M19 5.00001C22.988 8.80801 23.012 15.217 19 19M2 14.959V9.04001C2 8.46601 2.448 8.00001 3 8.00001H6.586C6.71833 7.99954 6.8492 7.97228 6.97071 7.91986C7.09222 7.86744 7.20185 7.79095 7.293 7.69501L10.293 4.30701C10.923 3.65101 12 4.11601 12 5.04301V18.957C12 19.891 10.91 20.352 10.284 19.683L7.294 16.314C7.20259 16.2153 7.09185 16.1365 6.96867 16.0825C6.84549 16.0285 6.71251 16.0004 6.578 16H3C2.448 16 2 15.534 2 14.959Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="circle"
                :class="[
                  { 'circle-active': selectedRight.id === i + 1 },
                  {
                    'circle-disabled':
                      disabledItems.length &&
                      disabledItems.find(
                        (element) => element.right.id === item.id
                      ),
                  },
                ]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  width="20px"
                  height="20px"
                >
                  <path
                    d="M10.4969 16L7 12.2079L7.87423 11.2599L10.4969 14.104L16.1258 8L17 8.94803L10.4969 16Z"
                    fill="white"
                  />
                </svg>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="match-mobile">
        Matching task is not available on your device. Try to use a device with
        a wider screen
      </div>
    </div>
  </article>
</template>

<script setup>
import { defineProps, ref, watch, defineEmits } from "vue";
import { getMediaContent } from "@/firebase.js";

const props = defineProps({
  task: {
    type: Object,
  },
  index: {
    type: Number,
  },
});

const emit = defineEmits(["setProgress"]);

const selectedTask = ref(null);
const selectedLeft = ref({ id: null });
const selectedRight = ref({ id: null, answer_id: null });

const itemHeight = 193;
const containerHeight = ref(0);

const audioTitle = ref();
const audioLeft = ref([]);
const audioRight = ref([]);

const leftItems = ref([]);
const rightItems = ref([]);

const disabledItems = ref([]);

const taskContent = ref(null);

watch(selectedLeft, (newVal) => {
  if (newVal.id !== null && selectedRight.value.id !== null) {
    disabledItems.value.push({ left: newVal, right: selectedRight.value });
    // Handle position only of the answer is correct or not
    if (newVal.id === selectedRight.value.answer_id) {
      handlePosition();
    }
    selectedLeft.value = { id: null };
    selectedRight.value = { id: null, answer_id: null };
  }
});

watch(selectedRight, (newVal) => {
  if (newVal.id !== null && selectedLeft.value.id !== null) {
    disabledItems.value.push({ left: selectedLeft.value, right: newVal });
    // Handle position only of the answer is correct or not
    if (selectedLeft.value.id === selectedRight.value.answer_id) {
      handlePosition();
    }
    selectedLeft.value = { id: null };
    selectedRight.value = { id: null, answer_id: null };
  }
});

watch(taskContent, (newVal, oldVal) => {
  if (oldVal === null && newVal !== null) {
    Array.from(rightItems.value).forEach((element, index) => {
      element.style.top = index * itemHeight + "px";
    });
    Array.from(leftItems.value).forEach((element, index) => {
      element.style.top = index * itemHeight + "px";
    });
  }
});

watch(disabledItems.value, (newVal) => {
  if (newVal.length === leftItems.value.length) {
    let count = 0;
    newVal.forEach((element) => {
      element.left.id === element.right.answer_id ? (count = count + 1) : null;
    });
    if (count === newVal.length) {
      emit("setProgress", props.index);
    }
  }
});

setTask();

function handlePosition() {
  let count = 0;
  let index = 0;
  for (let i = Array.from(disabledItems.value).length; i > 0; i--) {
    const left = Array.from(leftItems.value).find(
      (element) =>
        element.id === `match-left${disabledItems.value[i - 1].left.id}`
    );
    const right = Array.from(rightItems.value).find(
      (element) =>
        element.id === `match-right${disabledItems.value[i - 1].right.id}`
    );
    left.style.top = count * itemHeight + "px";
    right.style.top = count * itemHeight + "px";
    count = count + 1;
  }

  Array.from(leftItems.value).forEach((element) => {
    if (
      !Array.from(disabledItems.value).find(
        (item) => element.id === `match-left${item.left.id}`
      )
    ) {
      element.style.top = (index + count) * itemHeight + "px";
      index = index + 1;
    }
  });

  index = 0;

  Array.from(rightItems.value).forEach((element) => {
    if (
      !Array.from(disabledItems.value).find(
        (item) => element.id === `match-right${item.right.id}`
      )
    ) {
      element.style.top = (index + count) * itemHeight + "px";
      index = index + 1;
    }
  });
}

function hide() {
  if (selectedTask.value === props.index) {
    setTimeout(() => {
      selectedTask.value = null;
    }, 100);
  }
}

function isImage(string) {
  if (string) {
    return (
      string.includes(".png") ||
      string.includes(".jpg") ||
      string.includes(".svg")
    );
  }
}

function isSound(string) {
  if (string) {
    return (
      string.includes(".wav") ||
      string.includes(".aif") ||
      string.includes(".mp3") ||
      string.includes(".mid")
    );
  }
}

function playL(index) {
  audioLeft.value[index].play();
}

function playR(index) {
  audioRight[index].play();
}

function separate(id) {
  let elIndex = null;
  disabledItems.value.find((element, index) => {
    if (element.left.id === id) {
      elIndex = index;
    }
  });
  disabledItems.value.splice(elIndex, 1);
  handlePosition();
}

function setTask() {
  taskContent.value = {
    left: [],
    right: [],
  };

  props.task.image
    ? getMediaContent(props.task.image).then((url) => {
        taskContent.value.image = url;
      })
    : null;

  props.task.titleSound
    ? getMediaContent(props.task.titleSound).then((url) => {
        taskContent.value.titleSound = url;
      })
    : null;

  let count = 0;

  // Use index to save order in async function

  Array.from(props.task.left).forEach((item, index) => {
    taskContent.value.left.push({
      path: [],
      id: item.id,
    });
    Array.from(item.path).forEach((value, index2) => {
      getMediaContent(value).then((url) => {
        taskContent.value.left[index].path[index2] = url;
      });
    });
  });

  Array.from(props.task.right).forEach((item, index) => {
    taskContent.value.right.push({
      path: [],
      id: item.id,
      answer_id: item.answer_id,
    });
    count = count + 1;
    Array.from(item.path).forEach((value, index2) => {
      getMediaContent(value).then((url) => {
        taskContent.value.right[index].path[index2] = url;
      });
    });
  });

  containerHeight.value = count * itemHeight;
}
</script>

<style lang="scss" scoped>
.task-active {
  border: 6px solid #f2bdff;
}

.match {
  display: flex;
  gap: 20px;
  &-mobile {
    display: none;
  }
  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
  &-image {
    text-align: center;
    margin-bottom: 35px;
    img {
      max-width: 100%;
      max-height: 400px;
    }
  }
  &-item {
    border-radius: 8px;
    border: 2px solid $purple-200;
    position: absolute;
    top: 0;
    transition: top 0.8s ease 0s;
    width: 100%;
    background-color: #fff;
    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      height: 170px;
      padding: 15px;
      padding-left: 25px;
      > div {
        flex-shrink: 0;
      }
    }
    &-active {
      border-radius: 8px;
      border: 2px solid $purple-800;
      background-color: $white-bg;
    }
    &-disabled {
      border: 2px solid $white-bg;
      background-color: $white-bg;
    }
    &-container {
      display: flex;
      align-items: center;
      gap: 20px;
      .image-size-control {
        width: 140px;
        max-height: 140px;
        &-1 {
          width: 140px;
        }
        &-2 {
          width: 140px;
        }
        &-3 {
          width: 120px;
        }
        &-4 {
          width: 100px;
        }
      }
    }
  }
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 1px solid $purple-800;
    position: relative;
    svg {
      opacity: 0;
      position: absolute;
      left: -1px;
      top: -1px;
    }
    &-active {
      background-color: $purple-800;
      border: none;
      svg {
        opacity: 1;
      }
    }
    &-disabled {
      opacity: 0;
    }
  }
}

@media screen and (max-width: 1750px) {
  .match-item-container {
    max-width: 85%;
    flex-wrap: wrap;
    .image-size-control {
      &-3 {
        width: 100px;
      }
      &-4 {
        width: 80px;
      }
    }
  }
}

@media screen and (max-width: 1630px) {
  .match-item-container {
    gap: 5px 15px;
    .image-size-control {
      &-4 {
        width: 73px;
      }
    }
  }
}

@media screen and (max-width: 1505px) {
  .match-item-container {
    .image-size-control {
      &-3 {
        width: 80px;
      }
    }
  }
}

@media screen and (max-width: 1320px) {
  .match-item-container {
    .image-size-control {
      &-2 {
        width: 100px;
      }
    }
  }
}

@media screen and (max-width: 1120px) {
  .match-item-container {
    .image-size-control {
      &-2 {
        width: 90px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .match-item-container {
    .image-size-control {
      &-2 {
        width: 73px;
      }
      &-3 {
        width: 73px;
      }
    }
  }
}

@media screen and (max-width: 595px) {
  .task-item {
    display: none;
  }
  .match {
    display: none;
    &-mobile {
      display: block;
      font-size: 14px;
      color: $grey-200;
    }
  }
}
</style>
