<template>
  <div class="unit">
    <div>
      <input
        type="radio"
        :id="`Unit-${index}`"
        name="unit"
        :value="index"
        v-model="selectedUnit"
      />
      <label :for="`Unit-${index}`" class="unit-container">
        <p class="unit-title">Unit {{ index + 1 }}</p>
        <p class="unit-name">"{{ unit.title }}"</p>
      </label>
    </div>
    <div
      class="lessons-container"
      :class="{ 'lessons-container-visible': selectedUnit === index }"
    >
      <div v-if="selectedUnit === index" class="lessons-list">
        <div
          v-for="(lesson, i) in unit.lessons"
          :key="`Unit-${index}-${lesson.title}-${i}`"
        >
          <input
            type="radio"
            :id="`Unit-${index}-${lesson.title}-${i}`"
            :name="`Unit-${index}`"
            :value="i"
            v-model="selectedLesson"
          />
          <label
            :for="`Unit-${index}-${lesson.title}-${i}`"
            class="lessons-item"
            @click="$emit('isNav', false)"
            >Lesson {{ i + 1 }}</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits, onMounted } from "vue";

const props = defineProps({
  unit: {
    type: Object,
  },
  index: {
    type: Number,
  },
  activeUnit: {
    type: Number,
  },
  activeLesson: {
    type: Number,
  },
});

const emit = defineEmits(["activeUnit", "activeLesson", "isNav"]);

const selectedUnit = ref(null);
const selectedLesson = ref(null);

onMounted (() => {
  props.activeUnit !== null ? selectedUnit.value = props.activeUnit : selectedUnit.value;
  props.activeLesson !== null ? selectedLesson.value = props.activeLesson : selectedLesson.value;
})

watch(selectedUnit, (newVal) => {
  if(!isNaN(newVal)) {
    emit("activeUnit", newVal);
  }
  
});

watch(selectedLesson, (newVal) => {
  if(!isNaN(newVal)) {
    emit("activeLesson", newVal);
  }
});

watch(
  () => props.activeUnit,
  (newVal) => {
    selectedUnit.value = newVal;
    selectedLesson.value = null;
  }
);

watch(
  () => props.activeLesson,
  (newVal) => {
    selectedLesson.value = newVal;
  }
);

</script>

<style lang="scss" scoped>
.unit {
  input {
    position: absolute;
    opacity: 0;
    &:checked ~ label.unit-container {
      background-color: $purple-800;
      .unit-title {
        color: #fff;
      }
      .unit-name {
        color: #fff;
      }
    }
  }
  &-container {
    display: block;
    border-radius: 8px;
    padding: 12px 16px;
    cursor: pointer;
  }
  &-title {
    font-size: 18px;
    font-family: "Fixel Bold";
    line-height: normal;
    color: $purple-800;
    margin-bottom: 5px;
  }
  &-name {
    font-size: 14px;
  }
}

.lessons-container {
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.3s ease-out;
  overflow: hidden;
  &-visible {
    transform: scaleY(1);
  }
  .lessons-list {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 0;
    list-style: none;
    .lessons-item {
      display: block;
      padding: 18px;
      padding-left: 42px;
      height: 50px;
      border-radius: 8px;
      font-size: 14px;
      width: 100%;
      position: relative;
      cursor: pointer;
      &::before {
        content: "\2022";
        color: $purple-400;
        font-weight: bold;
        display: inline-block;
        width: 26px;
        font-size: 34px;
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    input:checked ~ .lessons-item {
      background-color: $purple-800;
      color: #fff;
    }
  }
}
</style>
