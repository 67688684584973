<template>
  <div class="platform">
    <NavigationComponent @is-nav="navHandler" :units="units" :isNav="isNav" />
    <ContentComponent @is-nav="navHandler" :units="units" :isNav="isNav" />
  </div>
</template>

<script setup>
import NavigationComponent from "@/components/platform/NavigationComponent.vue";
import ContentComponent from "@/components/platform/ContentComponent.vue";
import { scrollFix } from "../directives/scrollFix";
import { useUserStore } from "../store/user";
import { getUnits } from "@/firebase.js";
import { ref, watch } from "vue";

const userStore = useUserStore();
const units = ref([]);

const isSet = ref(false);
const isNav = ref(true);

scrollFix(false);

userStore.registerUser();

watch(userStore.auth, (newVal) => {
  if (newVal.currentUser && isSet.value === false) {
    setUnits();
    isSet.value = true;
  }
});

function setUnits() {
  getUnits(
    userStore.auth.currentUser.email ===
      userStore.demoEmailTemplate + userStore.emailAndPasswordTemplate
  ).then((querySnapshot) => {
    const filterUnits = [];
    if (querySnapshot.docs) {
      querySnapshot.forEach((doc) => {
        filterUnits.push(doc);
      });

      filterUnits
        .sort(
          (a, b) =>
            a.id.slice(a.id.indexOf("-") + 1, a.id.length) -
            b.id.slice(b.id.indexOf("-") + 1, b.id.length)
        )
        .forEach((doc) => {
          units.value.push(doc.data());
        });
    } else units.value.push(querySnapshot.data());

    userStore.setUnits(units.value);
  });
}

function navHandler(value) {
  isNav.value = value
}

</script>

<style lang="scss" scoped></style>
