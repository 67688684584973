<template>
  <article class="start">
    <p class="start-title" v-if="userStore.auth.currentUser.email ===  userStore.demoEmailTemplate + userStore.emailAndPasswordTemplate">
      Numbers of jumping is not available on demo account
    </p>
    <p class="start-title" v-else>
      Numbers of jumping: <span v-if="attempts !== null">{{ attempts }}</span>
    </p>
    <button class="button button-filled" @click="$emit('startLesson')">
      Start lesson
    </button>
  </article>
</template>

<script setup>
import { defineEmits, defineProps } from "vue";
import { useUserStore } from "@/store/user";

defineProps({
  attempts: {
    type: Number,
  },
});

defineEmits(["startLesson"]);

const userStore = useUserStore();

</script>

<style lang="scss" scoped>
.start {
  background-color: #fff;
  border-radius: 24px;
  padding: 30px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-title {
    font-size: 14px;
    color: $grey-200;
    display: flex;
    align-items: flex-end;
    line-height: 18px;
    span {
      font-size: 22px;
      margin-left: 30px;
    }
  }
}

.button {
  width: 270px;
}

@media screen and (max-width: 1024px) {
  .start {
    padding: 30px;
  }
}

@media screen and (max-width: 595px) {
  .start {
    display: block;
    &-title {
      margin-bottom: 25px;
    }
  }

}

@media screen and (max-width: 370px) {
  .start {
    .button {
      width: 100%;
    }
  }
}

</style>
