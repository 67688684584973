<template>
  <article v-if="item.unit && item.lesson">
    <div class="lesson">
      <div class="lesson-container">
        <div>
          <h3 class="lesson-title">{{ item.lesson.title }}</h3>
          <h4 class="unit-title">
            Unit {{ +route.query.unitId + 1 }}: "{{ item.unit.title }}"
          </h4>
        </div>
        <div class="emoji-container">
          <img
            src="@/assets/platform/progress/bad-active.svg"
            alt=""
            v-if="progressPercent < 40 && userStore.auth.currentUser.email !==  userStore.demoEmailTemplate + userStore.emailAndPasswordTemplate"
          />
          <img src="@/assets/platform/progress/bad-default.svg" alt="" v-else />
          <img
            src="@/assets/platform/progress/normal-active.svg"
            alt=""
            v-if="progressPercent >= 40 && progressPercent < 75 && userStore.auth.currentUser.email !==  userStore.demoEmailTemplate + userStore.emailAndPasswordTemplate"
          />
          <img
            src="@/assets/platform/progress/normal-default.svg"
            alt=""
            v-else
          />
          <img
            src="@/assets/platform/progress/good-active.svg"
            alt=""
            v-if="progressPercent >= 75 && userStore.auth.currentUser.email !==  userStore.demoEmailTemplate + userStore.emailAndPasswordTemplate"
          />
          <img
            src="@/assets/platform/progress/good-default.svg"
            alt=""
            v-else
          />
        </div>
      </div>
      <div class="progress" v-if="userStore.auth.currentUser.email ===  userStore.demoEmailTemplate + userStore.emailAndPasswordTemplate">
        Progress is not available on demo account
      </div>
      <div class="progress" v-else>
        Your Progress:
        <span class="progress-value">{{ progressPercent }}%</span>
      </div>
    </div>
  </article>
</template>

<script setup>
import { defineProps, watch, ref } from "vue";
import { useRoute } from "vue-router";
import { useUserStore } from "@/store/user";

const props = defineProps({
  progress: {
    type: Object,
  },
  item: {
    type: Object,
  },
});

const route = useRoute();
const progressPercent = ref(0);
const userStore = useUserStore();

watch(
  () => props.progress,
  (newVal) => {
    calculateProgressPercent(newVal);
  }
);

function calculateProgressPercent(val) {
  let countCompleted = 0;
  let countTasks = 0;
  for (let key in val) {
    if (key.includes("task")) {
      countTasks = countTasks + 1;
      val[key] ? (countCompleted = countCompleted + 1) : countCompleted;
    }
  }
  if (countCompleted === 0) {
    progressPercent.value = 0;
  } else {
    progressPercent.value = Math.round((countCompleted / countTasks) * 100);
  }
}
</script>

<style lang="scss" scoped>
.lesson {
  background-color: #fff;
  border-radius: 24px;
  padding: 40px 50px 45px;
  margin-bottom: 16px;
  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }
  &-title {
    font-size: 42px;
    margin-bottom: 10px;
  }
}

.unit {
  &-title {
    font-size: 16px;
  }
}

.emoji {
  &-container {
    display: flex;
    align-items: center;
    gap: 32px;
  }
}

.progress {
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  color: $grey-200;
  &-value {
    margin-left: 25px;
    font-size: 22px;
    line-height: 18px;
    color: $black-800;
  }
}

@media screen and (max-width: 1024px) {
  .lesson {
    padding: 30px;
  }
}

@media screen and (max-width: 595px) {
  .lesson {
    &-container {
      display: block;
      margin-bottom: 30px;
    }
  }
  .unit-title {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 370px) {
  .emoji-container {
    justify-content: space-between;
    gap: 8px;
  }
}

</style>
