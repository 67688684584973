<template>
  <article
    class="task"
    v-if="task"
    :class="{ 'task-active': selectedTask === index }"
  >
    <div>
      <input
        type="radio"
        :id="`task-${index}`"
        :name="`task-${index}`"
        :value="index"
        v-model="selectedTask"
      />
      <label :for="`task-${index}`" class="task-label" @click="hide()">
        <div class="task-title">
          <img src="@/assets/platform/task-icons/video.svg" alt="video-icon" />
          <h4>Watch a video</h4>
        </div>
        <img
          src="@/assets/platform/task-icons/arrow.svg"
          class="task-arrow-icon"
          :class="{ 'task-arrow-icon-active': selectedTask === index }"
        />
      </label>
    </div>
    <div
      class="task-container"
      :class="{ 'task-container-visible': selectedTask === index }"
    >
      <h4 class="task-item">
        <div v-if="titleSound !== null">
          <audio controls ref="audioTitle" class="audio-default">
            <source :src="titleSound" />
            Your browser does not support the audio element.
          </audio>
          <div class="audio-custom" @click.prevent="audioTitle.play()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <g clip-path="url(#clip0_722_970)">
                <path
                  d="M11.9641 6.40741C12.9515 7.72445 12.9515 10.2756 11.9641 11.5926M14.1863 3.81482C17.1404 6.63556 17.1582 11.383 14.1863 14.1852M1.59375 11.1919V6.80741C1.59375 6.38222 1.9256 6.03704 2.33449 6.03704H4.99079C5.08881 6.03669 5.18575 6.01649 5.27576 5.97766C5.36576 5.93884 5.44697 5.88218 5.51449 5.81111L7.73671 3.30148C8.20338 2.81556 9.00116 3.16 9.00116 3.84667V14.1533C9.00116 14.8452 8.19375 15.1867 7.73005 14.6911L5.51523 12.1956C5.44752 12.1225 5.36549 12.0641 5.27425 12.0241C5.183 11.9841 5.08449 11.9633 4.98486 11.963H2.33449C1.9256 11.963 1.59375 11.6178 1.59375 11.1919Z"
                  stroke="#1C1D28"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_722_970">
                  <rect
                    width="17.7778"
                    height="17.7778"
                    fill="white"
                    transform="translate(0.111328 0.111115)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        {{ task.title }}
      </h4>
      <div style="text-align: center">
        <iframe
          width="600"
          height="340"
          class="video"
          frameborder="0"
          allowfullscreen
          :src="`https://www.youtube.com/embed/${task.url}`"
        >
        </iframe>
      </div>
    </div>
  </article>
</template>

<script setup>
import { defineProps, ref, defineEmits } from "vue";
import { getMediaContent } from "@/firebase.js";

const props = defineProps({
  task: {
    type: Object,
  },
  index: {
    type: Number,
  },
});

const emit = defineEmits(["setProgress"]);

const selectedTask = ref(null);

const audioTitle = ref();

const titleSound = ref(null);

props.task.titleSound
  ? getMediaContent(props.task.titleSound).then((url) => {
      titleSound.value = url;
    })
  : null;

function hide() {
  if (selectedTask.value === props.index) {
    setTimeout(() => {
      selectedTask.value = null;
    }, 100);
  }
  emit("setProgress", props.index);
}
</script>

<style lang="scss" scoped>
.task-active {
  border: 6px solid #ffd74b;
}

@media screen and (max-width: 1100px) {
  .video {
    width: 100%;
    height: 280px;
  }
}

@media screen and (max-width: 550px) {
  .video {
    height: 200px;
  }
}

</style>
